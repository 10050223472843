/*
|––––––––––––––––––––––––––––––––––––––––––––––––––
| UTILITY: WIDTH
|––––––––––––––––––––––––––––––––––––––––––––––––––
*/


@include make-classes-from-list( w, width, $widths );

@include add-breakpoint-prefixes() {
  @include make-classes-from-list-for-prefixes( w, width, $widths );
}


/* ===== Max Widths ===== */
.copy-max {
  max-width: $copy_max;
}

.content-max {
  max-width: $content_max;
}

.site-max {
  max-width: $site_max;
}

@include add-breakpoint-prefixes() {
  &copy-max {
    max-width: $copy_max;
  }
  &content-max {
    max-width: $content_max;
  }
  &site-max {
    max-width: $site_max;
  }
}


/* ===== Force Full ===== */
// this must be inside a centered container
.force-full {
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  width: 100vw;
  max-width: unset;
}

@include add-breakpoint-prefixes() {
  &force-full {
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    width: 100vw;
    max-width: unset;
  }
}


/* ===== No Srink ===== */
// the size that it is and nothing less
.stiff {
  flex: 0 0 auto;
}

@include add-breakpoint-prefixes() {
  &stiff {
    flex: 0 0 auto;
  }
}


/* ===== Fill ===== */
// This is for an element you want to fill the space between others
.fill {
  flex: 1 1 auto;
}

@include add-breakpoint-prefixes() {
  &fill {
    flex: 1 1 auto;
  }
}
