/*
|––––––––––––––––––––––––––––––––––––––––––––––––––
| UTILITY: Matrix
|––––––––––––––––––––––––––––––––––––––––––––––––––
*/



/*
|–––––––––––––––––––––––––
| Matrix
|–––––––––––––––––––––––––
*/

.matrix {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@include add-breakpoint-prefixes() {
  &matrix {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}



/*
|–––––––––––––––––––––––––
| Cell
|–––––––––––––––––––––––––
*/

.cell {
  flex-grow: var(--cell-flex, 0);
  flex-shrink: var(--cell-flex, 0);
  flex-basis: var(--cell-width, auto);
  width: var(--cell-width, auto);
}

$cell_widths: (
  '1\\/12': calc( (1 / 12) * 100% ),
  '1\\/6': calc( (1 / 6) * 100% ),
  '1\\/5': 20%,
  '1\\/4': 25%,
  '1\\/3': calc( (1 / 3) * 100% ),
  '2\\/5': 40%,
  '5\\/12': calc( (5 / 12) * 100% ),
  '1\\/2': 50%,
  '7\\/12': calc( (7 / 12) * 100% ),
  '3\\/5': 60%,
  '2\\/3': calc( (2 / 3) * 100% ),
  '3\\/4': 75%,
  '4\\/5': 80%,
  '5\\/6': calc( (5 / 6) * 100% ),
  '11\\/12': calc( (11 / 12) * 100% ),
  full: 100%,
);

$cell_numbers: (
  12: 12,
  10: 10,
  8: 8,
  6: 6,
  5: 5,
  4: 4,
  3: 3,
  2: 2,
  full: 1,
);

.cells-auto,
.cell-auto {
  --cell-width: unset;
  --cell-flex: 0;
}

@include make-classes-from-list( 'cells', --cells-number, $cell_numbers ) {
  --cell-width: calc( (1 / var(--cells-number)) * 100% );
  --cell-flex: 0;
};

@include make-classes-from-list( 'cell', --cell-width, $cell_widths ) {
  --cell-flex: 0;
};

.cells-fill,
.cell-fill {
  --cell-width: auto;
  --cell-flex: 1;
}

@include add-breakpoint-prefixes() {
  &cells-auto,
  &cell-auto {
    --cell-width: unset;
    --cell-flex: 0;
  }

  @include make-classes-from-list-for-prefixes( 'cells', --cells-number, $cell_numbers ) {
    --cell-width: calc( (1 / var(--cells-number)) * 100% );
    --cell-flex: 0;
  };

  @include make-classes-from-list-for-prefixes( 'cell', --cell-width, $cell_widths ) {
    --cell-flex: 0;
  };

  &cells-fill,
  &cell-fill {
    --cell-width: auto;
    --cell-flex: 1;
  }
}



/*
|–––––––––––––––––––––––––
| Void
|–––––––––––––––––––––––––
*/

$void_space_x: var(--void-space-x);
$void_space_y: var(--void-space-y);

.void {
  --void-space-x: #{$size};
  --void-space-y: #{$size};
  margin-top: calc(-1 * (#{$void_space_x} / 2));
  margin-right: calc(-1 * (#{$void_space_y} / 2));
  margin-bottom: calc(-1 * (#{$void_space_x} / 2));
  margin-left: calc(-1 * (#{$void_space_y} / 2));

  & > * {
    padding-top: calc(#{$void_space_x} / 2);
    padding-right: calc(#{$void_space_y} / 2);
    padding-bottom: calc(#{$void_space_x} / 2);
    padding-left: calc(#{$void_space_y} / 2);
  }
}

@include make-classes-from-list( 'void', --void-space-x --void-space-y, $sizes );
@include make-classes-from-list( 'void-x', --void-space-x , $sizes );
@include make-classes-from-list( 'void-y', --void-space-y, $sizes );

@include add-breakpoint-prefixes() {
  &void {
    --void-space-x: #{$size};
    --void-space-y: #{$size};
  }
  @include make-classes-from-list-for-prefixes( 'void', --void-space-x --void-space-y, $sizes );

  &void-v {
    --void-space-x: #{$size};
  }
  @include make-classes-from-list-for-prefixes( 'void-x', --void-space-x , $sizes );

  &void-h {
    --void-space-y: #{$size};
  }
  @include make-classes-from-list-for-prefixes( 'void-y', --void-space-y, $sizes );
}
