/*
|––––––––––––––––––––––––––––––––––––––––––––––––––
| BASE: Dividers
|––––––––––––––––––––––––––––––––––––––––––––––––––
*/


/*
|–––––––––––––––––––––––––
| Horizontal Row
|–––––––––––––––––––––––––
*/

hr {
  margin: 0;
  border: none;
}

@mixin class_line {
  clear: both;
  margin: 0;
  border: unset;
  border-radius: 1px;
  width: 100%;
  height: 2px;
  background-color: currentColor;
  color: inherit;
}

.line {
  @include class_line;
}


/*
|–––––––––––––––––––––––––
| Line Break
|–––––––––––––––––––––––––
*/

br {
  clear: both;
  display: inline-block;
  width: 0;
  height: 1em;
  line-height: inherit;
}


/*
|–––––––––––––––––––––––––
| Spacers
|–––––––––––––––––––––––––
*/

/* === BREAK === */
.break {
  min-width: 100%;
  height: 1em;
  content: '';
}

/* === SPACE === */
.space {
  width: 1em;
  min-height: 100%;
  content: '';
}

/* === SPACER === */
.spacer {
  display: inline-block;
  width: 1em !important;
  height: 1em !important;
  flex: 0 0 none !important;
}


/*
|–––––––––––––––––––––––––
| Filler
|–––––––––––––––––––––––––
*/

// This is used as a sibling element inside a flex element to fill empty space
.filler {
  display: block;
  flex: 1 0 auto;
  content: '';
}
