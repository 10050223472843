.fancy-quote {
  display: block;
  margin: 2.4rem 0;

  &__copy {
    text-align: center;
    font-family: $heading_font;
    font-size: 1.6rem;
    line-height: 1.2;
  }

  &__accent {
    display: block;
    position: relative;
    margin-top: 1.2rem;
    margin-right: auto;
    margin-left: auto;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    background-color: currentColor;
    font-size: 8px;
    color: $accent;
    content: '';
  
    &::before,
    &::after {
      position: absolute;
      top: 50%;
      width: 4rem;
      transform: translate(0, -50%);
      height: 2px;
      background-color: currentColor;
      content: '';
    }
  
    &::before { left: 2em; }
  
    &::after { right: 2em; }
  }

  @include above( sm ) {
    padding: 0 10%;
  }
}