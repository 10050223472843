/*
|––––––––––––––––––––––––––––––––––––––––––––––––––
| BASE: Media
|––––––––––––––––––––––––––––––––––––––––––––––––––
*/

figure {
  display: block;
  margin: 0;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

video {
  display: block;
  width: 100%;
  height: auto;
}

svg {
  display: block;
  width: 100%;
  height: auto;
}

.icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  max-width: 1em;
  max-height: 1em;
  font-size: inherit;
}
