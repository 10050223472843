/*
|––––––––––––––––––––––––––––––––––––––––––––––––––
| COMPONENTS: Soloform
|––––––––––––––––––––––––––––––––––––––––––––––––––
*/

.soloform {
  $self: &;
  display: flex;
  flex-direction: row;

  &__input {
    flex: 1 0 auto;
    width: unset;
  }

  &__submit {
    min-width: unset;
    padding-left: 1.2em;
    padding-right: 1.2em;
  }

  @include below( sm ) {
    flex-direction: column;

    &__submit {
      margin-top: $size;
      width: 100%;
    }
  }	

  @include above( sm ) {
    &__input {
      border-top-right-radius: unset;
      border-bottom-right-radius: unset;
      border-right: transparent;
    }

    &__submit {
      border-top-left-radius: unset;
      border-bottom-left-radius: unset;
      border-left: transparent;
    }
  }
}
