/*
|––––––––––––––––––––––––––––––––––––––––––––––––––
| BASE: Template
|––––––––––––––––––––––––––––––––––––––––––––––––––
*/

*,
*::before,
*::after {
  box-sizing: border-box;
  /* border: 1px solid blueviolet; */
}

[hidden] {
  display: none !important;
}

[scroll-lock] {
  overflow: hidden !important;
  overflow: clip !important;
}

html {
  @include fluid-value(font-size, $min_width, $max_width, $font_min, $font_max);
}

body {
  margin: 0;
  overflow-x: hidden;
  overflow-x: clip;
  overflow-y: auto;
  background-color: $background_color;
  line-height: 1;
  font-size: 1rem;
  font-weight: normal;
  font-family: $copy_font;
  text-rendering: optimizeLegibility;
  color: $text_color;

  // Firefox only
  scroll-behavior: smooth;

  // Remove scollbar
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}


// this will, at the least, fill the viewport and gives the template structure
#site {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header {
  display: block;
}

// header comes first but should always be on top
#header {
  position: relative;
  z-index: 3;
}

main {
  display: block;
}

// main is in the middle and it should fill the space between but be below both the header and footer
#main {
  position: relative;
  z-index: 1;
  flex: 1 0 auto;
}

footer {
  display: block;
}

// footer 
#footer {
  position: relative;
  z-index: 2;
}

// seciton
section {
  display: block;
}
