/*
|––––––––––––––––––––––––––––––––––––––––––––––––––
| UTILITY: Color
|––––––––––––––––––––––––––––––––––––––––––––––––––
*/

// TODO: add color hover states
// .hover\:c\:primary:hover {
//   color: $primary;
// }

// .group:hover {
//   .group-hover\:c\:primary {
//     color: $primary;
//   }
// }


/* === TEXT COLORS === */
@include make-prefixed-classes-from-list( 'c', color, $theme_colors );


/* === BORDER COLORS === */
@include make-prefixed-classes-from-list( 'bd', border-color, $theme_colors );


/* === BACKGROUND COLORS === */
.transparent {
  background-color: transparent;
}

@include make-prefixed-classes-from-list( 'bg', background-color, $theme_colors );